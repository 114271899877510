import React from 'react'
import Form from 'react-bootstrap/Form';
import UserData from '../types/models';
  
class Editor extends React.Component<any, UserData> {
    constructor(props: any) {
      super(props);
    }

    render() {
        return (
            <Form>
            <Form.Group className="mb-3" controlId="email.Name">
              <Form.Label>Display Name</Form.Label>
              <Form.Control type="text" placeholder="John Doe" onChange={e => this.props.onDisplayNameChanged(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email.Position">
              <Form.Label>Job Title</Form.Label>
              <Form.Control type="text" placeholder="Job Title" onChange={e => this.props.onJobTitleChanged(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email.EmailAddress">
              <Form.Label>Email Address</Form.Label>
              <Form.Control type="email" placeholder="John.Doe@shinetherapy.org" onChange={e => this.props.onEmailChanged(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email.Phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="text" placeholder="+1 (555) 555-5555" onChange={e => this.props.onPhoneNumberChanged(e.target.value)} />
            </Form.Group>
          </Form>
          );
    }
}

export default Editor;