import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Editor from '../components/Editor';
import SignatureCard from '../components/Signature';
import UserData from '../types/models';

class HomePage extends React.Component<any, UserData> {
    constructor(props: any) {
      super(props);
      this.state = {
        displayName: "Wills Ward",
        jobTitle: "Technology Chair",
        emailAddress: "wills.ward@shinetherapy.org",
        phoneNumber: "+1 (817) 648-6230"
      };

      this.onDisplayNameChanged = this.onDisplayNameChanged.bind(this);
      this.onJobTitleChanged = this.onJobTitleChanged.bind(this);
      this.onEmailChanged = this.onEmailChanged.bind(this);
      this.onPhoneNumberChanged = this.onPhoneNumberChanged.bind(this);
    }

    onDisplayNameChanged(displayName: string) {
        this.setState({displayName: displayName});
    }

    onJobTitleChanged(jobTitle: string) {
        this.setState({jobTitle: jobTitle});
    }

    onEmailChanged(emailAddress: string) {
        this.setState({emailAddress: emailAddress});
    }

    onPhoneNumberChanged(phoneNumber: any) {
        this.setState({phoneNumber: phoneNumber});
    }

    render() {
        return (
            <Container className='pt-2'>
                <Row className='mb-4'>
                    <Col sm="12" md={{ span: 8, offset: 2}}>
                        <h2>Create Email Signature</h2>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col sm={12} md={{ span: 8, offset: 2}}>
                        Complete the form below with your information and press the copy button. Follow the instructions at <a href="https://aka.ms/emailsignature">Adding an Email Signature</a> to add the signature to your outgoing emails.
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={{ span: 4, offset: 2}}>
                        <Editor 
                            onDisplayNameChanged={this.onDisplayNameChanged}
                            onJobTitleChanged={this.onJobTitleChanged}
                            onEmailChanged={this.onEmailChanged}
                            onPhoneNumberChanged={this.onPhoneNumberChanged} />
                    </Col>
                    <Col sm={12} md={{ span: 4 }}>
                        <SignatureCard userData={this.state}/>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default HomePage
