import React from 'react'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import UserData from '../types/models';
import { renderToStaticMarkup } from 'react-dom/server'
import logo from '../img/shine-therapy.png'

class SignatureCard extends React.Component<{userData: UserData}, any> {
    constructor(props: {userData: UserData}) {
      super(props);

      this.onCopy = this.onCopy.bind(this);
      this.handler = this.handler.bind(this);
    }

    handler(event: any) {
      const element = 
        <tbody id="Signature">
          <tr>
            <td style={{lineHeight: 1}}>
              <span style={{fontSize: 12, fontFamily: 'Segoe UI, sans-serif', fontWeight: 'bold'}}>
                <span>{this.props.userData.displayName}< br/></span>
              </span>
              <span style={{fontSize: 12, fontFamily: 'Segoe UI, sans-serif'}}>
                <span>{this.props.userData.jobTitle}< br/></span>
                <span><a href={`mailto:${this.props.userData.emailAddress}`}>{this.props.userData.emailAddress}</a><br/></span>
                <span>{this.props.userData.phoneNumber}< br/></span>
              </span>
              <br />
              <img 
                style={{maxWidth: 115, border: 'medium'}}
                width="115" src={window.location.origin + '/shine-therapy-120.png'} />
            </td>
          </tr>
        </tbody>;

      const text = `${this.props.userData.displayName}
${this.props.userData.jobTitle}
Shine Therapy, Inc.
${this.props.userData.emailAddress}
${this.props.userData.phoneNumber}
https://shinetherapy.org`

      const html = renderToStaticMarkup(element);
      event.clipboardData.setData('text/html', html);
      event.clipboardData.setData('text/plain', text);
      event.preventDefault();
      document.removeEventListener('copy', this.handler, true);
    }

    onCopy() {
      document.addEventListener('copy', this.handler, true);
      document.execCommand('copy');
    }

    render() {
        return (
            <Card>
              <Card.Body>
                <Card.Text>
                    <Container>
                      <tbody id="Signature">
                        <tr>
                          <td style={{lineHeight: 1}}>
                            <span style={{fontSize: 12, fontFamily: 'Segoe UI, sans-serif', fontWeight: 'bold'}}>
                              <span>{this.props.userData.displayName}< br/></span>
                            </span>
                            <span style={{fontSize: 12, fontFamily: 'Segoe UI, sans-serif'}}>
                              <span>{this.props.userData.jobTitle}< br/></span>
                              <span><a href={`mailto:${this.props.userData.emailAddress}`}>{this.props.userData.emailAddress}</a><br/></span>
                              <span>{this.props.userData.phoneNumber}< br/></span>
                            </span>
                            <br />
                            <img width="115" src={logo}/>
                          </td>
                        </tr>
                      </tbody>
                    </Container>
                </Card.Text>
                <Button variant='primary' onClick={this.onCopy}>Copy</Button>
              </Card.Body>
            </Card>
          );
    }
}

export default SignatureCard;